<script setup>
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth.js'
import { useSubscriptionStore } from '~/store/subscription.js'
import { prepareLayout } from '~/utils/auth.js'
const appConfig = useAppConfig()

console.log('rendering layout...')
const { $api } = useNuxtApp()
const store = useAuthStore()
const subscriptionStore = useSubscriptionStore()
const router = useRouter()

const { orgDetails } = usePayments()
const { role } = storeToRefs(store)
// Define a shared server and client state variable for the contractor boards in the navigation
// This will initially be empty on first render until the user has been authenticated
const contractorsForNav = useState('contractors_nav', () => [])

const { data: details } = await useAsyncData('details', () => orgDetails())

prepareLayout(appConfig, store, contractorsForNav)

/**
 * Helper that maps a list of contractor objects to navigation items
 *
 * @param {object[]} contractors
 * @returns {object[]}
 */
const mapContractorNavItems = (contractors) =>
  contractors.map((c) => ({
    name: c.name,
    to: `/board/${c.id}`,
    icon: {
      name: 'material-symbols:line-start-circle-outline-rounded',
      class: 'w-4 h-4',
    },
  }))

let isInactive = false

let isUnpaid = false

const isMultiBoard = ref(details.value.data.multipleBoardsEnabled)
const contractorId = ref(details.value.data.contractor_id)

/**
 * This watcher is triggered on first render and whenever the contractorsForNav shared state changes.
 *
 * Because the contractors get fetched asynchronously (after the token resolves), the first time this watcher runs,
 * it will have an empty array in contractorsForNav.
 */
const subscriptionData = await subscriptionStore.getSubscription()
watch(
  contractorsForNav,
  async () => {
    const subscriptionStatus = subscriptionData.status

    isInactive =
      (subscriptionStatus === null ||
        subscriptionStatus.toLowerCase() === 'inactive') &&
      role.value.name !== 'org_product_owner'

    isUnpaid =
      subscriptionStatus !== null &&
      subscriptionStatus.toLowerCase() === 'unpaid'
    if (isInactive || isUnpaid) {
      router.push('/payment')
    } else if (!isMultiBoard.value) {
      const contractorNavigation = {
        name: 'Claims',
        icon: { name: 'ph:stack', class: 'w-5 h-5' },
        activePath: '',
        to: `/board/${contractorId.value}`,
      }

      appConfig.tairo.collapse.navigation.items.push(contractorNavigation)
    } else {
      console.log('watch contractors for nav', contractorsForNav.value)
      const contractorNavigation = {
        name: 'Claims',
        icon: { name: 'ph:stack', class: 'w-5 h-5' },
        activePath: '',
        children: mapContractorNavItems(contractorsForNav.value),
      }

      const contractorBoardsItemIndex =
        appConfig.tairo.collapse.navigation.items.findIndex((item) =>
          item ? item.name === 'Claims' : false,
        )

      // If already has contractor boards nav items
      if (contractorBoardsItemIndex > -1) {
        appConfig.tairo.collapse.navigation.items.splice(
          contractorBoardsItemIndex,
          1,
          contractorNavigation,
        )
      } else {
        appConfig.tairo.collapse.navigation.items.push(contractorNavigation)
      }
    }
  },
  { immediate: true },
)

console.log('...done rendering layout...')
</script>

<template>
  <div v-if="!store.isLoaded || store.user === null">
    <div
      class="bg-slate-100 dark:bg-[#121212] h-screen flex flex-col justify-center items-center"
    >
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span
        class="block font-heading text-sm mt-2.5 text-slate-400 dark:text-muted-100"
      >
        Please Wait</span
      >
    </div>
  </div>
  <TairoCollapseLayout
    v-if="!isInactive && store.isLoaded && store.user !== null"
  >
    <slot />
  </TairoCollapseLayout>
</template>
